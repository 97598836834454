<form #form [formGroup]="formGroup" [appApiAction]="formPromise" (ngSubmit)="submit()">
  <div class="tw-container tw-mb-3">
    <div class="tw-mb-6">
      <h2 class="tw-mb-3 tw-text-base tw-font-semibold">{{ "billingPlanLabel" | i18n }}</h2>
      <div class="tw-mb-1 tw-items-center" *ngFor="let selectablePlan of selectablePlans">
        <label class="tw- tw-block tw-text-main" for="interval{{ selectablePlan.type }}">
          <input
            checked
            class="tw-h-4 tw-w-4 tw-align-middle"
            id="interval{{ selectablePlan.type }}"
            name="plan"
            type="radio"
            [value]="selectablePlan.type"
            formControlName="plan"
          />
          <ng-container *ngIf="selectablePlan.isAnnual">
            <ng-container *ngIf="selectablePlan.PasswordManager">
              {{ "annual" | i18n }} -
              {{
                (selectablePlan.PasswordManager.basePrice === 0
                  ? selectablePlan.PasswordManager.seatPrice
                  : selectablePlan.PasswordManager.basePrice
                ) | currency : "$"
              }}
              /{{ "yr" | i18n }}
            </ng-container>
            <ng-container *ngIf="!selectablePlan.PasswordManager && selectablePlan.SecretsManager">
              {{ "annual" | i18n }} -
              {{
                (selectablePlan.SecretsManager.basePrice === 0
                  ? selectablePlan.SecretsManager.seatPrice
                  : selectablePlan.SecretsManager.basePrice
                ) | currency : "$"
              }}
              /{{ "yr" | i18n }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!selectablePlan.isAnnual">
            <ng-container *ngIf="selectablePlan.PasswordManager">
              {{ "monthly" | i18n }} -
              {{
                (selectablePlan.PasswordManager.basePrice === 0
                  ? selectablePlan.PasswordManager.seatPrice
                  : selectablePlan.PasswordManager.basePrice
                ) | currency : "$"
              }}
              /{{ "monthAbbr" | i18n }}
            </ng-container>
            <ng-container *ngIf="!selectablePlan.PasswordManager && selectablePlan.SecretsManager">
              {{ "monthly" | i18n }} -
              {{
                (selectablePlan.SecretsManager.basePrice === 0
                  ? selectablePlan.SecretsManager.seatPrice
                  : selectablePlan.SecretsManager.basePrice
                ) | currency : "$"
              }}
              /{{ "monthAbbr" | i18n }}
            </ng-container>
          </ng-container>
        </label>
      </div>
    </div>

    <div class="tw-mb-4">
      <h2 class="tw-mb-3 tw-text-base tw-font-semibold">{{ "paymentType" | i18n }}</h2>
      <app-payment [hideCredit]="true" [trialFlow]="true"></app-payment>
      <app-tax-info [trialFlow]="true" (onCountryChanged)="changedCountry()"></app-tax-info>
    </div>

    <div class="tw-flex tw-space-x-2">
      <button type="submit" buttonType="primary" bitButton [loading]="form.loading">
        {{ "startTrial" | i18n }}
      </button>

      <button bitButton type="button" buttonType="secondary" (click)="stepBack()">Back</button>
    </div>
  </div>
</form>
